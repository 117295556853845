import React, { ChangeEvent } from "react";
import {
  Button,
  Box,
  Input,
  VStack,
  Heading,
  HStack,
  Spacer,
  Link,
} from "@chakra-ui/core";
import { Link as RouterLink } from "react-router-dom";

import { Case } from "../types";
import {
  useCasesQuery,
  useCreateCaseMutation,
  useDeleteCaseMutation,
} from "../hooks/queries";
import { Card } from "../theme-components/Card";

/**
 * COMPONENT: CASES
 */
function Cases() {
  const [newCaseField, setNewCaseField] = React.useState<string>("");
  const [
    createCaseMutation,
    { loading: casesMutationLoading },
  ] = useCreateCaseMutation();
  const [deleteCaseMutation] = useDeleteCaseMutation();
  const {
    data: casesQueryData,
    error: casesQueryError,
    loading: casesQueryLoading,
  } = useCasesQuery();

  function createCase() {
    createCaseMutation({ variables: { name: newCaseField } }).then(() => {
      setNewCaseField("");
    });
  }

  function deleteCase(id: string) {
    return deleteCaseMutation({ variables: { id: id } });
  }

  return (
    <Box p={4}>
      <Heading>Cases</Heading>
      <Box mb={4} mt={4}>
        New case:
        <HStack>
          <Input
            value={newCaseField}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setNewCaseField(e.target.value)
            }
          />
          <Button isLoading={casesMutationLoading} onClick={createCase}>
            Add
          </Button>
        </HStack>
      </Box>

      {casesQueryLoading && "Loading..."}

      {!casesQueryLoading && !casesQueryError && casesQueryData && (
        <VStack mt={2}>
          {casesQueryData.cases.edges.map(
            ({ node: caseItem }: { node: Case }) => (
              <CaseListItem
                key={caseItem.objectId}
                itemKey={caseItem.objectId}
                caseItem={caseItem}
                actions={{ deleteCase: deleteCase }}
              />
            )
          )}
        </VStack>
      )}
    </Box>
  );
}

/**
 * COMPONENT: CASE LIST ITEM
 */
type CaseListItemActions = { deleteCase: Function };
type CaseListItemProps = {
  caseItem: Case;
  itemKey: string;
  actions: CaseListItemActions;
};
function CaseListItem({ caseItem, itemKey, actions }: CaseListItemProps) {
  const [isDeleting, setIsDeleting] = React.useState(false);

  function runDeleteAction() {
    setIsDeleting(true);
    actions.deleteCase(itemKey).then(() => setIsDeleting(false));
  }

  return (
    <Card>
      <Link as={RouterLink} to={`/cases/${itemKey}`}>
        {caseItem.name}
      </Link>
      <Spacer />
      <Button
        isLoading={isDeleting}
        onClick={runDeleteAction}
        colorScheme={"red"}
      >
        Delete
      </Button>
    </Card>
  );
}

export default Cases;
