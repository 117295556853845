import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/core";
import { useForm } from "react-hook-form";
import { useAuth } from "../hooks/authentication";

type SignupFormData = {
  email: string;
  username: string;
  password: string;
};

/**
 * COMPONENT: SIGNUP FORM
 */
function SignupForm() {
  const { signUp, errorMessage, isLoading } = useAuth();
  const { register, handleSubmit, errors } = useForm<SignupFormData>();

  const onSubmit = handleSubmit((data) => {
    signUp(data.username, data.password, data.email);
  });

  return (
    <Box p={4}>
      <form onSubmit={onSubmit}>
        <FormControl>
          <FormLabel>Email address</FormLabel>
          <Input type="email" name="email" ref={register({ required: true })} />
          {errors.username && (
            <FormHelperText textColor={"red"}>Email is required</FormHelperText>
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Username</FormLabel>
          <Input name="username" ref={register({ required: true })} />
          {errors.username && (
            <FormHelperText textColor={"red"}>
              Username is required
            </FormHelperText>
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            name="password"
            ref={register({ required: true })}
          />
          {errors.password && (
            <FormHelperText textColor={"red"}>
              Password is required
            </FormHelperText>
          )}
        </FormControl>
        <Button mt={4} type={"submit"}>
          Sign up
        </Button>
        {isLoading && "Loading..."}
        {errorMessage && (
          <Box p={4} mt={4} rounded={4} bg="tomato" textColor="white">
            {errorMessage}
          </Box>
        )}
      </form>
    </Box>
  );
}

export default SignupForm;
