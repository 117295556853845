import React from "react";
import { Box, Container } from "@chakra-ui/core";

/**
 * COMPONENT: APP CONTAINER
 */
export function AppContainer({ children }: { children: React.ReactNode }) {
  return (
    <Box bg={"gray.50"} minHeight={"100vh"}>
      <Container maxWidth="1000px">{children}</Container>
    </Box>
  );
}
