import React, { ReactChildren } from "react";
import {
  Box,
  Button,
  Container,
  Fade,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SlideFade,
  Spacer,
} from "@chakra-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/authentication";
import LoginForm from "./LoginForm";

/**
 * COMPONENT: SITE HEADER
 */
function SiteHeader() {
  const { sessionToken } = useAuth();

  return (
    <Box bg={"blue.500"} color={"white"}>
      <Container maxWidth="1000px">
        <Flex as={"nav"} p={4}>
          <Box fontSize={26}>CaseKeeper 9000</Box>

          <Spacer />

          <MenuLink to="/">Home</MenuLink>
          {sessionToken && <MenuLink to="/cases">Cases</MenuLink>}
          {sessionToken && <MenuLink to="/settings">Settings</MenuLink>}
          <MenuLink to="/about">About</MenuLink>

          <Spacer />

          <LoginButton />
        </Flex>
      </Container>
    </Box>
  );
}

/**
 * COMPONENT: MENU LINK
 */
type MenuLinkProps = { to: string; children: ReactChildren | string };
function MenuLink({ to, children }: MenuLinkProps) {
  return (
    <Link p={2} as={RouterLink} to={to}>
      {children}
    </Link>
  );
}

/**
 * COMPONENT: LOGIN BUTTON
 */
function LoginButton() {
  const { userInfo, signOut, sessionToken } = useAuth();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (sessionToken) {
      setIsModalOpen(false);
    }
  }, [sessionToken]);

  return (
    <>
      <Flex>
        {userInfo ? (
          <>
            <Box fontSize={12} textAlign="right" mr={2}>
              <b>Logged in</b>
              <br />
              {userInfo.username}
            </Box>
            <Box>
              <Button color="black" onClick={signOut}>
                Log out
              </Button>
            </Box>
          </>
        ) : (
          <Button color="black" onClick={() => setIsModalOpen(true)}>
            Log in
          </Button>
        )}
      </Flex>
      <LoginModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

/**
 * COMPONENT: LOGIN MODAL
 */
type LoginModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
};
function LoginModal({ isOpen, onRequestClose }: LoginModalProps) {
  return (
    <Fade timeout={300} in={isOpen}>
      {(styles) => (
        <Modal onClose={onRequestClose} isOpen={true} isCentered>
          <ModalOverlay style={styles}>
            <SlideFade timeout={150} in={isOpen} unmountOnExit={false}>
              {(styles) => (
                <ModalContent style={styles}>
                  <ModalHeader>Login</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <LoginForm />
                  </ModalBody>
                  <ModalFooter>
                    {/*<Button colorScheme="blue" mr={3} onClick={onRequestClose}>*/}
                    {/*  Log in*/}
                    {/*</Button>*/}
                    <Button variant="ghost" onClick={onRequestClose}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </ModalContent>
              )}
            </SlideFade>
          </ModalOverlay>
        </Modal>
      )}
    </Fade>
  );
}

export default SiteHeader;
