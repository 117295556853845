import { Flex } from "@chakra-ui/core";
import React from "react";

/**
 * COMPONENT: CARD
 */
export function Card({ children }: { children: React.ReactNode }) {
  return (
    <Flex bg={"white"} p={4} rounded={"lg"} w={"100%"}>
      {children}
    </Flex>
  );
}
