import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Link,
} from "@chakra-ui/core";
import { useForm } from "react-hook-form";
import { useUpdateUserSettings } from "../hooks/queries";
import { useAuth } from "../hooks/authentication";
import { UserSettings } from "../types";

type SettingsFormData = {} & UserSettings;

/**
 * COMPONENT: SETTINGS PAGE
 */
function SettingsPage() {
  const { userInfo } = useAuth();
  const [
    updateUserSettingsMutation,
    { loading: isUpdateUserSettingsMutationLoading },
  ] = useUpdateUserSettings();
  const { register, handleSubmit, setValue } = useForm<
    SettingsFormData
  >();

  React.useEffect(() => {
    if (userInfo?.settings?.alphavantageAPIKey) {
      setValue("alphavantageAPIKey", userInfo.settings.alphavantageAPIKey);
    }
  }, [userInfo, setValue]);

  const onSubmit = handleSubmit((data) => {
    if (userInfo) {
      updateUserSettingsMutation({
        variables: { userId: userInfo.id, settingsObj: data },
      });
    }
  });

  return (
    <Box p={4}>
      <Heading>Settings</Heading>
      <p>Settings for your account</p>
      <br />
      <Heading as="h3" fontSize={20}>
        API Settings
      </Heading>
      <form onSubmit={onSubmit}>
        <FormControl>
          <FormLabel>Alpha Vantage API key</FormLabel>
          <Input
            type="text"
            name="alphavantageAPIKey"
            ref={register({ required: true })}
          />
          <FormHelperText>
            You need this in order to see stock prices in the app. Get you API
            key here:{" "}
            <Link
              href="https://www.alphavantage.co/support/#api-key"
              target="_blank"
            >
              https://www.alphavantage.co/support/#api-key
            </Link>
          </FormHelperText>
        </FormControl>
        <Button
          mt={4}
          type="submit"
          isLoading={isUpdateUserSettingsMutationLoading}
        >
          Save settings
        </Button>
      </form>
    </Box>
  );
}

export default SettingsPage;
