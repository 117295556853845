import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const cache = new InMemoryCache();
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_PARSE_SERVER_GQL_URL
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "X-Parse-Application-Id": process.env.REACT_APP_PARSE_APPLICATION_ID,
      "X-Parse-Javascript-Key": process.env.REACT_APP_PARSE_JAVASCRIPT_KEY,
    },
  };
});

export function getClientWithSession(sessionToken: string | null) {
  const sessionLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "X-Parse-Session-Token": sessionToken,
      },
    };
  });

  let link;
  if (sessionToken) link = sessionLink.concat(authLink.concat(httpLink));
  else link = authLink.concat(httpLink);

  return new ApolloClient({
    // Provide required constructor fields
    cache: cache,
    link: link,
  });
}
