import { Box, Heading } from "@chakra-ui/core";
import React from "react";

/**
 * COMPONENT: HOME PAGE
 */
function HomePage() {
  return (
    <Box p={4}>
      <Heading>CaseKeeper 9000</Heading>
      <p>This is the home page. Not sure what will go here yet...</p>
    </Box>
  );
}

export default HomePage;
