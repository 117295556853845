import React from "react";
import { Box, Heading, Text } from "@chakra-ui/core";

/**
 * COMPONENT: ABOUT PAGE
 */
function AboutPage() {
  return (
    <Box p={4}>
      <Heading>About CaseKeeper 9000</Heading>
      <Text>This page is about this app</Text>
    </Box>
  );
}

export default AboutPage;
