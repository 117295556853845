import { gql, useQuery, useMutation } from "@apollo/client";

const fragments = {
  caseListFields: gql`
    fragment CaseListFieldsFragment on Case {
      id
      objectId
      name
      ticker
    }
  `,

  caseItemFields: gql`
    fragment CaseItemFieldsFragment on Case {
      id
      objectId
      name
      ticker
    }
  `,

  caseStoryListFields: gql`
    fragment CaseStoryListFieldsFragment on CaseStory {
      id
      objectId
      name
      case {
        id
        objectId
      }
    }
  `,
};

/**
 * CREATE CASE
 */
export function useCreateCaseMutation() {
  return useMutation(
    gql`
      mutation createCase($name: String!) {
        createCase(input: { fields: { name: $name } }) {
          case {
            ...CaseListFieldsFragment
          }
        }
      }
      ${fragments.caseListFields}
    `,
    {
      update(cache, { data: { createCase } }) {
        cache.modify({
          fields: {
            cases(existingCases = []) {
              const newCaseRef = cache.writeFragment({
                data: createCase.case,
                fragment: fragments.caseListFields,
              });
              return { edges: [...existingCases.edges, { node: newCaseRef }] };
            },
          },
        });
      },
    }
  );
}

/**
 * UPDATE CASE
 */
export function useUpdateCaseMutation() {
  return useMutation(gql`
    mutation updateCase($caseId: ID!, $name: String, $ticker: String) {
      updateCase(
        input: { id: $caseId, fields: { name: $name, ticker: $ticker } }
      ) {
        case {
          ...CaseItemFieldsFragment
        }
      }
    }
    ${fragments.caseItemFields}
  `);
}

/**
 * READ CASES
 */
export function useCasesQuery() {
  return useQuery(
    gql`
      query GetCases {
        cases {
          edges {
            node {
              ...CaseListFieldsFragment
            }
          }
        }
      }
      ${fragments.caseListFields}
    `
  );
}

/**
 * READ CASE
 */
export function useCaseQuery(id: string) {
  return useQuery(
    gql`
      query ReadCaseQuery($id: ID!) {
        case(id: $id) {
          ...CaseItemFieldsFragment
        }
        caseStories(where: { case: { have: { objectId: { equalTo: $id } } } }) {
          edges {
            node {
              ...CaseStoryListFieldsFragment
            }
          }
        }
      }
      ${fragments.caseItemFields}
      ${fragments.caseStoryListFields}
    `,
    { variables: { id: id } }
  );
}

/**
 * DELETE CASE
 */
export function useDeleteCaseMutation() {
  return useMutation(
    gql`
      mutation deleteCase($id: ID!) {
        deleteCase(input: { id: $id }) {
          case {
            id
          }
        }
      }
    `,
    {
      update(cache, { data: { deleteCase } }) {
        cache.modify({
          fields: {
            cases(existingCases, { readField }) {
              return {
                edges: existingCases.edges.filter(
                  (caseRef: any) =>
                    deleteCase.case.id !== readField("id", caseRef.node)
                ),
              };
            },
          },
        });
      },
    }
  );
}

/**
 * CREATE CASE STORY
 */
export function useCreateStoryMutation() {
  return useMutation(
    gql`
      mutation createCaseStory($caseId: ID!, $name: String!) {
        createCaseStory(
          input: { fields: { case: { link: $caseId }, name: $name } }
        ) {
          caseStory {
            ...CaseStoryListFieldsFragment
          }
        }
      }
      ${fragments.caseStoryListFields}
    `,
    {
      refetchQueries: ["ReadCaseQuery"],
    }
  );
}

/**
 * DELETE CASE STORY
 */
export function useDeleteCaseStoryMutation() {
  return useMutation(
    gql`
      mutation deleteCaseStory($caseStoryId: ID!) {
        deleteCaseStory(input: { id: $caseStoryId }) {
          caseStory {
            id
          }
        }
      }
    `,
    {
      update(cache, { data: { deleteCaseStory } }) {
        cache.modify({
          fields: {
            caseStories(existingCaseStories, { readField }) {
              return {
                edges: existingCaseStories.edges.filter(
                  (caseStoryRef: any) =>
                    deleteCaseStory.caseStory.id !==
                    readField("id", caseStoryRef.node)
                ),
              };
            },
          },
        });
      },
    }
  );
}

/**
 * UPDATE SETTINGS
 */
export function useUpdateUserSettings() {
  return useMutation(gql`
    mutation updateUserSettings($userId: ID!, $settingsObj: Object!) {
      updateUser(input: { id: $userId, fields: { settings: $settingsObj } }) {
        user {
          id
          settings
        }
      }
    }
  `);
}
