import React from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Heading,
  HStack,
  Input,
  Spacer,
  VStack,
  InputGroup,
  InputRightAddon,
  Spinner,
} from "@chakra-ui/core";
import { EditIcon } from "@chakra-ui/icons";
import {
  useCaseQuery,
  useCreateStoryMutation,
  useDeleteCaseStoryMutation,
  useUpdateCaseMutation,
} from "../hooks/queries";
import { Story } from "../types";
import { Card } from "../theme-components/Card";
import { useAuth } from "../hooks/authentication";

/**
 * COMPONENT: CASE PAGE
 */
function CasePage() {
  const { caseKey } = useParams<{ caseKey: string }>();
  const { loading, error, data } = useCaseQuery(caseKey);
  const [
    createStoryMutation,
    { loading: isCreateStoryMutationLoading },
  ] = useCreateStoryMutation();
  const [
    setTickerMutation,
    { loading: isTickerMutationLoading },
  ] = useUpdateCaseMutation();
  const [deleteCaseStoryMutation] = useDeleteCaseStoryMutation();
  const { price } = useQuote(data?.case?.ticker);
  const [newStoryField, setNewStoryField] = React.useState("");

  function createStory() {
    return createStoryMutation({
      variables: { caseId: caseKey, name: newStoryField },
    }).then(() => {
      setNewStoryField("");
    });
  }

  function deleteStory(id: string) {
    return deleteCaseStoryMutation({ variables: { caseStoryId: id } });
  }

  function setTicker(newValue: string) {
    return setTickerMutation({
      variables: { caseId: caseKey, ticker: newValue },
    });
  }

  if (loading) return <Box p={4}>Loading...</Box>;

  if (error) return <Box p={4}>Error :(</Box>;

  return (
    <Box p={4}>
      <Heading>{data.case.name}</Heading>

      <InPlaceEditField
        value={data.case.ticker}
        placeholder="Set ticker"
        onSave={setTicker}
        isLoading={isTickerMutationLoading}
      />

      {data.case.ticker && price && (
        <Box>
          <b>Last closing price</b>
          <br />
          {price}
        </Box>
      )}

      <Box mb={4} mt={4}>
        New story:
        <HStack>
          <Input
            value={newStoryField}
            onChange={(e) => setNewStoryField(e.target.value)}
          />
          <Button
            isLoading={isCreateStoryMutationLoading}
            onClick={createStory}
          >
            Add
          </Button>
        </HStack>
      </Box>

      <VStack mt={2}>
        {data.caseStories.edges.map(({ node: storyItem }: { node: Story }) => (
          <StoryListItem
            key={storyItem.objectId}
            itemKey={storyItem.objectId}
            storyItem={storyItem}
            actions={{ delete: deleteStory }}
          />
        ))}
      </VStack>
    </Box>
  );
}

/**
 * COMPONENT: STORY LIST ITEM
 */
type StoryListItemActions = { delete: Function };
type StoryListItemProps = {
  storyItem: Story;
  itemKey: string;
  actions: StoryListItemActions;
};
function StoryListItem({ storyItem, itemKey, actions }: StoryListItemProps) {
  const [isDeleting, setIsDeleting] = React.useState(false);

  function runDeleteAction() {
    setIsDeleting(true);
    actions.delete(itemKey).then(() => setIsDeleting(false));
  }

  return (
    <Card>
      {storyItem.name}
      <Spacer />
      <Button
        isLoading={isDeleting}
        onClick={runDeleteAction}
        colorScheme="red"
      >
        Delete
      </Button>
    </Card>
  );
}

/**
 * COMPONENT: IN-PLACE EDIT FIELD
 */
type InPlaceEditFieldProps = {
  value: string;
  placeholder: string;
  onSave: (newValue: string) => void;
  isLoading?: boolean;
};
function InPlaceEditField({
  value,
  placeholder,
  onSave,
  isLoading,
}: InPlaceEditFieldProps) {
  const [fieldValue, setFieldValue] = React.useState(value);
  const [isEditing, setIsEditing] = React.useState(false);
  const [showEditIcon, setShowEditIcon] = React.useState(false);
  const inputField = React.createRef<HTMLInputElement>();
  const saveButton = React.createRef<HTMLDivElement>();
  const editIcon = React.createRef<SVGSVGElement>();

  // Keep field value up to date
  React.useEffect(() => {
    setFieldValue(value);
  }, [value]);

  // Abort if user clicks outside or hits ESC-key
  React.useEffect(() => {
    const abort = () => {
      setIsEditing(false);
      setShowEditIcon(false);
      setFieldValue(value);
    };
    const abortOnOutsideClick = (e: Event) => {
      if (
        e.target !== inputField.current &&
        e.target !== saveButton.current &&
        e.target !== editIcon.current &&
        // @ts-ignore
        e.target.parentNode.parentNode !== editIcon.current
      )
        abort();
    };
    const abortOnEsc = (e: KeyboardEvent) => {
      if (e.key === "Escape") abort();
    };

    document.body.addEventListener("mousedown", abortOnOutsideClick);
    document.addEventListener("keydown", abortOnEsc);

    return () => {
      document.body.removeEventListener("mousedown", abortOnOutsideClick);
      document.removeEventListener("keydown", abortOnEsc);
    };
  }, [inputField, saveButton, value, editIcon, ]);

  function save() {
    onSave(fieldValue);
    setIsEditing(false);
    setShowEditIcon(false);
  }

  return !isEditing ? (
    <Box
      as="span"
      cursor="pointer"
      onClick={() => setIsEditing(true)}
      onMouseEnter={() => setShowEditIcon(true)}
      onMouseLeave={() => setShowEditIcon(false)}
    >
      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        <>
          {value ? (
            value
          ) : (
            <Box as="span" color="gray.500" fontStyle="italic">
              {placeholder}
            </Box>
          )}
          {showEditIcon && <EditIcon ref={editIcon} color="gray.500" ml="2" />}
        </>
      )}
    </Box>
  ) : (
    <InputGroup>
      <Input
        value={fieldValue}
        onChange={(e) => setFieldValue(e.target.value)}
        width="150"
        onKeyPress={(e) => e.key === "Enter" && save()}
        ref={inputField}
      />
      <InputRightAddon
        as={Button}
        isLoading={isLoading}
        onClick={save}
        ref={saveButton}
      >
        Save
      </InputRightAddon>
    </InputGroup>
  );
}

/**
 * HOOK: USE QUOTE
 */
function useQuote(symbol: string) {
  const { userInfo } = useAuth();
  const [price, setPrice] = React.useState(0);

  React.useEffect(() => {
    if (symbol && userInfo?.settings?.alphavantageAPIKey) {
      fetch(
        `https://www.alphavantage.co/query?function=GLOBAL_QUOTE&symbol=${symbol}&apikey=${userInfo.settings.alphavantageAPIKey}`
      )
        .then((r) => r.json())
        .then((d) => {
          setPrice(d["Global Quote"]["05. price"]);
        });
    }
  }, [symbol, userInfo]);

  return { price };
}

export default CasePage;
