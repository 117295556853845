import React from "react";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ChakraProvider, Box } from "@chakra-ui/core";

import Cases from "./components/Cases";
import CasePage from "./components/CasePage";
import HomePage from "./components/HomePage";
import AboutPage from "./components/AboutPage";
import SignupForm from "./components/SignupForm";
import { AuthProvider, useAuth } from "./hooks/authentication";
import theme from "./theme";
import { AppContainer } from "./theme-components/AppContainer";
import SiteHeader from "./components/SiteHeader";
import SettingsPage from "./components/SettingsPage";

/**
 * COMPONENT: APP
 */
function App() {
  return (
    <AuthProvider>
      <AppInner />
    </AuthProvider>
  );
}

/**
 * COMPONENT: APP INNER
 */
function AppInner() {
  const { apolloClient } = useAuth();

  return (
    <ApolloProvider client={apolloClient}>
      <ChakraProvider resetCSS theme={theme}>
        <Router>
          <SiteHeader />
          <AppContainer>
            <Switch>
              <Route path={"/cases/:caseKey"}>
                <LoggedInRoute>
                  <CasePage />
                </LoggedInRoute>
              </Route>
              <Route path="/cases">
                <LoggedInRoute>
                  <Cases />
                </LoggedInRoute>
              </Route>
              <Route path="/about">
                <AboutPage />
              </Route>
              <Route path="/settings">
                <SettingsPage />
              </Route>
              <Route path="/signup">
                <SignupForm />
              </Route>
              <Route path="/">
                <HomePage />
              </Route>
            </Switch>
          </AppContainer>
        </Router>
      </ChakraProvider>
    </ApolloProvider>
  );
}

/**
 * COMPONENT: LOGGED IN ROUTE
 */
function LoggedInRoute({ children }: { children: React.ReactNode }) {
  const { sessionToken } = useAuth();

  if (sessionToken) return <>{children}</>;

  return <Box p={4}>You need to be logged in to view this page</Box>;
}

export default App;
