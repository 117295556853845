import { merge } from "@chakra-ui/utils";
import { theme } from "@chakra-ui/core";

export default merge(theme, {
  colors: {
    blue: {
      50: "#e2eeff",
      100: "#b4cdff",
      200: "#84acfb",
      300: "#548bf6",
      400: "#266af3",
      500: "#0c51d9",
      600: "#053faa",
      700: "#012d7b",
      800: "#001b4c",
      900: "#00091f",
    },
    gray: {
      50: "#f2f2f2",
      100: "#d9d9d9",
      200: "#bfbfbf",
      300: "#a6a6a6",
      400: "#8c8c8c",
      500: "#737373",
      600: "#595959",
      700: "#404040",
      800: "#262626",
      900: "#0d0d0d",
    },
    red: {
      50: "#ffeade",
      100: "#ffc6b1",
      200: "#fda281",
      300: "#fb7e51",
      400: "#f95a20",
      500: "#df4106",
      600: "#ae3104",
      700: "#7d2302",
      800: "#4c1400",
      900: "#200300",
    },
  },
});
