import React from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/authentication";

type LoginFormData = {
  username: string;
  password: string;
};

/**
 * COMPONENT: LOGIN SCREEN
 */
function LoginForm() {
  const { signIn, isLoading, errorMessage, sessionToken } = useAuth();

  const { register, errors, handleSubmit } = useForm<LoginFormData>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = handleSubmit(({ username, password }) => {
    signIn(username, password);
  });

  if (sessionToken)
    return (
      <Box p={4}>
        <Heading>Welcome!</Heading>
      </Box>
    );

  return (
    <Box p={4}>
      <Heading>Log in</Heading>
      <Box>
        <form onSubmit={onSubmit}>
          <FormControl>
            <FormLabel>Username</FormLabel>
            <Input name="username" ref={register({ required: true })} />
            {errors.username && (
              <FormHelperText textColor={"red"}>
                Username is required
              </FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              name="password"
              ref={register({ required: true })}
            />
            {errors.password && (
              <FormHelperText textColor={"red"}>
                Password is required
              </FormHelperText>
            )}
          </FormControl>

          <Box mt={4}>
            <Button mr={4} as={Link} to="/signup">
              Sign up
            </Button>
            <Button type={"submit"}>Log in</Button>
            {isLoading && "Loading..."}
          </Box>
          {errorMessage && (
            <Box p={4} mt={4} rounded={4} bg="tomato" textColor="white">
              {errorMessage}
            </Box>
          )}
        </form>
      </Box>
    </Box>
  );
}

export default LoginForm;
