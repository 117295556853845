import React from "react";
import { useCookies } from "react-cookie";
import {
  ApolloClient,
  ApolloError,
  gql,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { getClientWithSession } from "../apolloclient";
import { UserSettings } from "../types";

// --

const SIGNUP_MUTATION = gql`
  mutation signUp($username: String!, $password: String!, $email: String!) {
    signUp(
      input: {
        fields: { username: $username, password: $password, email: $email }
      }
    ) {
      viewer {
        sessionToken
        user {
          id
          objectId
          username
          email
          settings
        }
      }
    }
  }
`;

const SIGNIN_MUTATION = gql`
  mutation signIn($username: String!, $password: String!) {
    logIn(input: { username: $username, password: $password }) {
      viewer {
        sessionToken
        user {
          id
          objectId
          username
          email
          settings
        }
      }
    }
  }
`;

const SIGNOUT_MUTATION = gql`
  mutation signOut {
    logOut(input: { clientMutationId: null }) {
      viewer {
        user {
          id
        }
      }
    }
  }
`;

const VIEWER_QUERY = gql`
  query {
    viewer {
      sessionToken
      user {
        id
        objectId
        email
        username
        settings
      }
    }
  }
`;

// --

export interface UserInfo {
  id: string;
  username: string;
  email: string;
  settings: UserSettings;
}

type AuthState = {
  sessionToken: string | null;
  errorMessage: string | null;
  isLoading: boolean;
  user: UserInfo | null;
  apolloClient: ApolloClient<any>;
};

type AuthActions = {
  signUp: (username: string, password: string, email: string) => void;
  signIn: (username: string, password: string) => void;
  signOut: () => void;
};

const initialAuthState = {
  sessionToken: null,
  errorMessage: null,
  user: null,
  isLoading: false,
  apolloClient: getClientWithSession(null),
};

const AuthContext = React.createContext<AuthState>(initialAuthState);
const ActionsContext = React.createContext<AuthActions | null>(null);

// --

/**
 * useAuth hook
 * Use this hook to get references to the signin, signup, and signout functions.
 * The hook also returns session token, loading status and error messages.
 */
type UseAuthReturnType = {
  signUp: AuthActions["signUp"];
  signIn: AuthActions["signIn"];
  signOut: AuthActions["signOut"];
  userInfo: UserInfo | null;
  errorMessage: string | null;
  sessionToken: string | null;
  isLoading: boolean;
  apolloClient: ApolloClient<any>;
};
function useAuth(): UseAuthReturnType {
  const { signUp, signIn, signOut } = React.useContext(
    ActionsContext
  ) as AuthActions;
  const authState = React.useContext(AuthContext);

  let errorMessage = null;
  let sessionToken = null;
  let userInfo = null;
  let isLoading = false;

  if (authState) {
    errorMessage = authState.errorMessage;
    sessionToken = authState.sessionToken;
    userInfo = authState.user;
    isLoading = authState.isLoading;
  }

  return {
    signUp,
    signIn,
    signOut,
    userInfo,
    errorMessage,
    sessionToken,
    isLoading,
    apolloClient: authState.apolloClient,
  };
}

// --

/**
 * AuthProvider Component
 * Everything that needs to access auth data through the useAuth hook needs to be a child of this component.
 */
type AuthProviderProps = {
  children: React.ReactNode;
};
const CK_SESSION_COOKIE = "ckSession";
const cookieOptions = {
  path: "/",
  maxAge: 60 * 60 * 24,
  //secure: true,
  sameSite: true,
};
function AuthProvider({ children }: AuthProviderProps) {
  const [authState, setAuthState] = React.useState<AuthState>(initialAuthState);
  const [signupMutation] = useMutation(SIGNUP_MUTATION, {
    client: authState.apolloClient,
  });
  const [signinMutation] = useMutation(SIGNIN_MUTATION, {
    client: authState.apolloClient,
  });
  const [signoutMutation] = useMutation(SIGNOUT_MUTATION, {
    client: authState.apolloClient,
  });
  const [
    getViewer,
    {
      loading: viewerQueryLoading,
      data: viewerQueryData,
      error: viewerQueryError,
      called: viewerQueryCalled,
    },
  ] = useLazyQuery(VIEWER_QUERY, {
    client: authState.apolloClient,
  });
  const [cookies, setCookie, removeCookie] = useCookies([CK_SESSION_COOKIE]);

  React.useEffect(() => {
    if (
      !viewerQueryCalled &&
      !viewerQueryLoading &&
      authState.user === null &&
      cookies[CK_SESSION_COOKIE]
    ) {
      getViewer();
      authState.apolloClient.stop();
      setAuthState((authState) => ({
        ...authState,
        isLoading: true,
        apolloClient: getClientWithSession(cookies[CK_SESSION_COOKIE]),
      }));
    }

    if (viewerQueryCalled) {
      if (viewerQueryData) {
        setAuthState((authState) => ({
          ...authState,
          sessionToken: viewerQueryData.viewer.sessionToken,
          user: {
            id: viewerQueryData.viewer.user.objectId,
            username: viewerQueryData.viewer.user.username,
            email: viewerQueryData.viewer.user.email,
            settings: viewerQueryData.viewer.user.settings,
          },
        }));
      }

      if (viewerQueryError) {
        setAuthState(initialAuthState);
        removeCookie(CK_SESSION_COOKIE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewerQueryData,
    viewerQueryError,
    cookies,
    removeCookie,
    getViewer,
    viewerQueryCalled,
    viewerQueryLoading,
  ]);

  function signUp(username: string, password: string, email: string) {
    removeCookie(CK_SESSION_COOKIE);
    setAuthState({ ...initialAuthState, isLoading: true });
    signupMutation({
      variables: {
        username: username,
        password: password,
        email: email,
      },
    })
      .then((result) => {
        const sessionToken = result.data.signUp.viewer.sessionToken;
        authState.apolloClient.stop();
        setAuthState({
          ...initialAuthState,
          sessionToken: sessionToken,
          isLoading: false,
          errorMessage: null,
          user: {
            id: result.data.signUp.viewer.user.objectId,
            username: result.data.signUp.viewer.user.username,
            email: result.data.signUp.viewer.user.email,
            settings: result.data.signUp.viewer.user.settings,
          },
          apolloClient: getClientWithSession(sessionToken),
        });
        setCookie(CK_SESSION_COOKIE, sessionToken, cookieOptions);
      })
      .catch((error: ApolloError) =>
        setAuthState({
          ...initialAuthState,
          isLoading: false,
          errorMessage: error.message,
        })
      );
  }

  function signIn(username: string, password: string) {
    removeCookie(CK_SESSION_COOKIE);
    setAuthState({ ...authState, isLoading: true });
    signinMutation({
      variables: {
        username: username,
        password: password,
      },
    })
      .then((result) => {
        const sessionToken = result.data.logIn.viewer.sessionToken;
        authState.apolloClient.stop();
        setAuthState({
          ...initialAuthState,
          sessionToken: sessionToken,
          isLoading: false,
          errorMessage: null,
          user: {
            id: result.data.logIn.viewer.user.objectId,
            username: result.data.logIn.viewer.user.username,
            email: result.data.logIn.viewer.user.email,
            settings: result.data.logIn.viewer.user.settings,
          },
          apolloClient: getClientWithSession(sessionToken),
        });
        setCookie(CK_SESSION_COOKIE, sessionToken, cookieOptions);
      })
      .catch((error: ApolloError) =>
        setAuthState({
          ...initialAuthState,
          isLoading: false,
          errorMessage: error.message,
        })
      );
  }

  function signOut() {
    setAuthState({ ...authState, isLoading: true });
    signoutMutation()
      .then(() => {
        authState.apolloClient.stop();
        setAuthState(initialAuthState);
        removeCookie(CK_SESSION_COOKIE);
      })
      .catch((error: ApolloError) => {
        setAuthState({
          ...authState,
          isLoading: false,
          errorMessage: error.message,
        });
      });
  }

  const authActions: AuthActions = {
    signUp: signUp,
    signIn: signIn,
    signOut: signOut,
  };

  return (
    <ActionsContext.Provider value={authActions}>
      <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
    </ActionsContext.Provider>
  );
}

// --

export { AuthProvider, useAuth };
